.RedGradientAlert {
  background: linear-gradient(90deg, #e46f55 0%, #b54ec6 100%);

  @apply text-gray-200;

  a {
    @apply text-blue-600;
  }

  &.withInteractionEffect {
    cursor: pointer;

    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        linear-gradient(90deg, #e46f55 0%, #b54ec6 100%);
    }

    &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(90deg, #e46f55 0%, #b54ec6 100%);
    }
  }
}
