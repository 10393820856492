.details {
  a, strong {
    @apply text-yellow-400 no-underline;
  }
  em {
    @apply text-green-500 not-italic;
  }
  h2, strong {
    font-weight: 500;
  }
  table {
    th {
      @apply bg-gray-800 font-bold;
    }
    th, td {
      @apply border-gray-600 px-2.5 py-3;
      border-width: 1px;
      strong {
        @apply font-bold;
      }
    }
  }
}