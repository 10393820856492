.container {
  background-image: linear-gradient(92.64deg, #2C41DF 0.43%, #E132DA 41.92%);
  overflow: hidden;
  position: relative;
  z-index: 0;
}
// < 640px image align bottom
// >= 640px image align right with min-width 55rem
.container-image {
  position: absolute;
  z-index: -1;
  background: center bottom / 100% auto no-repeat url("lockdrop_bg_narrow.png");
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  @media (min-width: 640px) {
    background-image: url('lockdrop_bg_wide.png');
    background-size: auto 100%;
    background-position: right center;
    min-width: 55rem;
  }
}